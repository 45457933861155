import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.css']
})
export class AutoComponent {
  isGurney = false;
  isSkipLegB = true;
  isZone = false;
  isAuto = false;
  isSkipThirdDayWav = false;
  isSkipTomorrow = true;
  restrictedLimit = 50;
  currentDate = new Date().toISOString().split('T')[0].split('-');
  price = 40;
  loadedCharacter: {};
  apiURL = 'https://bolt.alcsolutions.com/api/';
  postApiURL = `${this.apiURL}events/`;
  getApiURL = `${this.apiURL}trips/open/?geographyState=CA`;
  authHeader: any;
  authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlcklkIjoiQ0FMQTA0ODciLCJ1c2VyTmFtZSI6ImdyZWVubWVkdHJhbnNpbmNAZ21haWwuY29tIiwiaXNzIjoiQm9sdEFwaSIsImF1ZCI6IkFMQ1NvbHV0aW9ucyJ9.PlN5YBAjCfzQq6W_fQOJT1bMBTLy_-O-q0Uf33P04XM';
  selectedTrips = [];
  existingTrips = [];
  requestedTrips = 0;
  wcTrips = 0;
  gurTrips = 0;
  blackListCities = ['MENIFEE'];
  // drivers = [];
  // citiesList = [];
  trips = [];
  timeZone = 'America/Los_Angeles';
  // citiesList = [
  //   "APPLE VALLEY", "HESPERIA", "ADELANTO", "VICTORVILLE", "PINON HILLS", "NEWBERRY SPRINGS",
  //   "LUCERNE VALLEY", "BARSTOW", "HELENDALE", "SUGARLOAF", "BIG BEAR LAKE", "BIG BEAR CITY",
  //   "DAGGETT", "LOMA LINDA", "SAN BERNARDINO", "PHELAN", "COLTON"
  // ];
  citiesList = ["HEMET"];
  drivers = [  // Manual Salma,  Green med, lisa
    { "driverId": "37068", "providerId": "CALA0487", "driverName": "SALMAN RIZVI", "phoneNumber": "(442) 255-9259", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
    // { "driverId": "37073", "providerId": "CALA0487", "driverName": "SYEDFARMAN RIZVI", "phoneNumber": "(760) 780-5773", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
    // { "driverId": "37074", "providerId": "CALA0487", "driverName": "SATPAL SINGH", "phoneNumber": "(818) 718-7173", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
    // { "driverId": "38254", "providerId": "CALA0487", "driverName": "LASHANAE YARBROUGH", "phoneNumber": "(760) 684-2246", "email": "aztecarios1320@gmail.com", "eligibleToService": "Y" },
    { "driverId": "38356", "providerId": "CALA0487", "driverName": "FERNANDO ISAAC", "phoneNumber": "(909) 648-8111", "email": "green,edtransinc@gmail.com", "eligibleToService": "Y" },
    { "driverId": "39906", "providerId": "CALA0487", "driverName": "LISA PARTAKERS", "phoneNumber": "(760) 713-2756", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
    //  { "driverId": "40060", "providerId": "CALA0487", "driverName": "GREEN MED TRANS 2", "phoneNumber": "(818) 358-8883", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" }
  ];

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    const abbrs = {
      EST: '(Eastern Standard Time)',
      EDT: '(Eastern Daylight Time)',
      CST: '(Central Standard Time)',
      CDT: '(Central Daylight Time)',
      MST: '(Mountain Standard Time)',
      MDT: '(Mountain Daylight Time)',
      PST: '(Pacific Standard Time)',
      PDT: '(Pacific Daylight Time)',
    };

    moment.fn.zoneName = function () {
      let abbr = this.zoneAbbr();
      return abbrs[abbr] || abbr;
    };
    console.log('Moment Current Time:: ', moment.tz(this.timeZone).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z zz'));
    this.activatedRoute.params.subscribe(params => {
      console.log(params['type']);
      const type = params['type'];
      if (type === 'auto') {
        this.isAuto = true;
      } else if (type === 'zone') {
        this.isAuto = true;
        this.isZone = true;
      }
      this.setConfig();
      this.getTrips();
    });
    this.startTimer();
  }
  startTimer() {
    setInterval(() => {
      console.clear();
    }, 30000);
  }
  setConfig() {
    if (this.isAuto) {
      this.drivers = [
        { "driverId": "37068", "providerId": "CALA0487", "driverName": "SALMAN RIZVI", "phoneNumber": "(442) 255-9259", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
        { "driverId": "37073", "providerId": "CALA0487", "driverName": "SYEDFARMAN RIZVI", "phoneNumber": "(760) 780-5773", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" },
        { "driverId": "42496", "providerId": "CALA0487", "driverName": "FREDRICK PARTAKER", "phoneNumber": "(760) 437-8088", "email": "fjpartaker@gmail.com", "eligibleToService": "Y" }
        // { "driverId": "37074", "providerId": "CALA0487", "driverName": "SATPAL SINGH", "phoneNumber": "(818) 718-7173", "email": "greenmedtransinc@gmail.com", "eligibleToService": "Y" }
        // { "driverId": "38254", "providerId": "CALA0487", "driverName": "LASHANAE YARBROUGH", "phoneNumber": "(760) 684-2246", "email": "aztecarios1320@gmail.com", "eligibleToService": "Y" }
      ];
    }
    if (this.isZone) {
      this.citiesList = ["VICTORVILLE", "HESPERIA", "APPLE VALLEY"];
      this.price = 9;
      this.restrictedLimit = 35;
    }
  }

  getRandomDriver() {
    const driverIndex = Math.floor(Math.random() * 3); // (Math.random() * 5 | 0) + 1;
    const driver = this.drivers[driverIndex];
    return driver['driverId'];
  }
  skipTomorrow() {
    this.isSkipTomorrow = !this.isSkipTomorrow;
  }
  skipThirdDayWav() {
    this.isSkipThirdDayWav = !this.isSkipThirdDayWav;
  }
  skipLegB() {
    this.isSkipLegB = !this.isSkipLegB;
  }
  skipGurney() {
    this.isGurney = !this.isGurney;
  }
  getTrips() {
    let authHeader = new HttpHeaders({
      authorization: this.authorization
    });
    let options = { headers: authHeader };
    let serviceLevel = '&service_level=WC,D2D,C2C';
    if (this.isGurney) {
      serviceLevel = '&service_level=WC,D2D,C2C,GUR';
    }
    this.http.get(`${this.getApiURL}${serviceLevel}`, options)
      .subscribe((trips: any) => {
        console.log(trips);
        this.selectedTrips = [];
        // this.trips = [];
        let date = moment.tz(this.timeZone).format('M/D');
        trips.forEach(el => {
          let dateTime = el.dueTime.split(' ');
          // console.log(`Trip ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueTime} --- ${el.puCity} --- ${this.cities.hasOwnProperty(el.puCity)} --- ${el.doCity} --- ${this.cities.hasOwnProperty(el.puCity) && this.cities[el.puCity].includes(el.doCity)} --- $${Number(el['payAmount'] || 0)} --- ${Number(el.tripMiles)}mi`);
          console.log(`${date} -- ${dateTime.includes(date)} -- Trip ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueTime} --- ${el.puCity} --- ${this.citiesList.includes(el.puCity)} --- ${el.doCity} --- ${this.citiesList.includes(el.doCity)} --- $${Number(el['payAmount'] || 0)} --- ${Number(el.tripMiles)}mi`);
          if (this.citiesList.includes(el.puCity) || this.citiesList.includes(el.doCity)) {
            if (this.isZone && !this.citiesList.includes(el.doCity)) {
              console.log('DropOff city is not match');
              return false;
            }
            
            if (el.serviceLevel == 0) {
              return false;
            }

            /**
             * July 06
             */
            if (el.dueTime) {
              //let dateTime = el.dueTime.split(' ');

              //let date = `${Number(this.currentDate[1])}/${Number(this.currentDate[2])}`;
              console.log('Today Trip Skiped --- ', date, 'Matched', dateTime.includes(date))
              if (dateTime.includes(date)) {
                return false;
              }

              if (this.isSkipTomorrow) {
                const tomorrow = moment.tz(date, ['MM/DD', 'M/D', 'MM/D', 'M/DD'], this.timeZone).add(1, 'days').format('M/D');
                if (dateTime.includes(tomorrow)) {
                  console.log(el.dueTime, ' ---- Moment Tomorrow --- ', tomorrow);
                  return false;
                }
              }
              if (this.isSkipThirdDayWav && el.serviceLevel === "WC") {
                const tomorrow = moment.tz(date, ['MM/DD', 'M/D', 'MM/D', 'M/DD'], this.timeZone).add(2, 'days').format('M/D');
                if (dateTime.includes(tomorrow)) {
                  console.log(el.dueTime, ' ---- Moment Third Day Wav --- ', tomorrow);
                  return false;
                }
              }

              //  console.log(dateTime);
              if (dateTime.includes('11:47') && dateTime.includes('PM') && this.isSkipLegB) {
                console.log(`LEGB Trip ${el.boltTripId} --- ${el.serviceLevel} --- Due Date: ${el.dueTime} --- ${el.puCity} --- ${this.citiesList.includes(el.puCity)} --- ${el.doCity} --- ${this.citiesList.includes(el.doCity)} --- $${Number(el['payAmount'] || 0)} --- ${Number(el.tripMiles)}mi`);
                return false;
              }
            }
            // return false;
            // if (this.citiesList.includes(el.puCity) && Number(el['payAmount'] || 0) >= Number(this.price)) {
            //   if (this.isZone && !this.citiesList.includes(el.doCity)) {
            //     console.log('DropOff city is not match');
            //     return false;
            //   }
            el.matchTime = moment.tz(this.timeZone).format('MM/DD/YYYY HH:mm');
            if (el.serviceLevel === "GUR" && this.gurTrips < 2) {
              this.gurTrips += 1;
              this.assignTrips(el);
              this.trips.push(el);
              return true;
            }
            if (el.serviceLevel === "WC" && this.wcTrips < 8) {
              this.wcTrips += 1;
              this.assignTrips(el);
              this.trips.push(el);
              return true;
            }

            if (el.serviceLevel === "D2D" || el.serviceLevel === 'C2C') {
              this.assignTrips(el);
              this.trips.push(el);
              return true;
            }

            //  return false;
          }

          return false;
        });
        console.log('Trips matched count --- ', this.selectedTrips.length);
        if (this.isAuto) {
          if (this.selectedTrips.length > 0) {
            this.confirmTrips();
          } else {
            this.getTrips();
          }
        }
      }, err => {
        console.log('HTTP Error --- ', err)
        this.getTrips();
      });
  }

  generateRequest(params) {

    let authHeader = new HttpHeaders({
      authorization: this.authorization,
      'Content-Type': 'application/json'
    });
    let options = { headers: authHeader };
    return this.http.post(this.postApiURL, params, options);

  }

  confirmTrips() {
    console.log('Request Matched and Send to Server --- ', this.selectedTrips.length);
    forkJoin(this.selectedTrips).subscribe(results => {
      console.log('Proceed --- ');
      if (this.isAuto) {
        this.getTrips();
      }
    }, err => {
      console.log('HTTP Error --- ', err)
      this.getTrips();
    });
    console.log('Re Fetch Trips from server');
  }

  assignTrips(t) {

    if (this.existingTrips.length && this.existingTrips.includes(t.boltTripId)) {
      console.log(`Already Exist TripId ${t.boltTripId} `);
      return false;
    }
    console.log(this.selectedTrips, this.requestedTrips, this.restrictedLimit);
    if (this.requestedTrips >= this.restrictedLimit) {
      console.log('Requested Limit Exceeded from limit ---- ', this.selectedTrips, this.requestedTrips, this.restrictedLimit);
      return false;
    }
    let driverId = '37074'; // Saptal singh ID  // this.getRandomDriver();
    let boltTripIds = t.boltTripId;
    if (t.associatedPassengerTrips.length) {
      boltTripIds = `${boltTripIds},${t.associatedPassengerTrips.join()}`;
    }
    let tripParams = {
      "boltTripIds": boltTripIds,
      "eventType": "confirm",
      "time": moment.tz(this.timeZone).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z zz'), // Date(),
      "driverId": driverId
    };
    console.log(`Confirm Trip ${t.boltTripId} --- ${t.serviceLevel} --- Due Date: ${t.dueTime} --- ${t.puCity} --- ${t.doCity} --- $${Number(t['payAmount'] || 0)} --- ${Number(t.tripMiles)}mi`)
    // Leg A
    this.requestedTrips += 1;
    this.selectedTrips.push(this.generateRequest(JSON.stringify(tripParams)));
    this.existingTrips.push(t.boltTripId);
    // Assosiate Trip
    if (t.associatedPassengerTrips.length) {
      t.associatedPassengerTrips.forEach(boltTripId => {
        // tripParams['boltTripId'] = boltTripId;
        this.requestedTrips += 1;
        this.existingTrips.push(boltTripId);
        console.log(`Confirm Trip Associate ${boltTripId} --- ${t.serviceLevel} --- Due Date: ${t.dueTime} --- ${t.puCity} --- ${t.doCity} --- $${Number(t['payAmount'] || 0)} --- ${Number(t.tripMiles)}mi`)
      });
    }

  }
}
